export class Logger {
    static debug(text) {
        console.debug(text);
    }

    static info(text) {
        console.info(text);
    }

    static warn(text) {
        console.warn(text);
    }

    static error(text) {
        console.error(text);
    }
}
