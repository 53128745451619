export function byId(id) {
    return document.getElementById(id);
}

export function cleanupObject(object) {
    for (const i in object) delete object[i];
}

export function toleranceTest(a, b, tolerance) {
    return (a - tolerance) <= b && b <= (a + tolerance);
}

export function prettyPrintTime(seconds) {
    const minutes = ~~(seconds / 60);
    if (minutes < 1) return '<1 min';
    const hours = ~~(minutes / 60);
    if (hours < 1) return `${minutes}min`;
    const days = ~~(hours / 24);
    if (days < 1) return `${hours}h`;
    return `${days}d`;
}